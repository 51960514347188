// 导入对ajax封装的axios
import axios from 'axios'
import store from '@/store/index'
import router from '@/router'
import logins from "@/api/modules/login"; // 用户登录登出api
// 按需引用elementui组件
import {Message, Dialog,MessageBox} from 'element-ui'

// 全局修改默认配置，Dialog点击空白处不能关闭弹窗
Dialog.props.closeOnClickModal.default = false
// 全局修改默认配置，Dialog按下ESC不能关闭弹窗
Dialog.props.closeOnPressEscape.default = false

// 用创建实例的方式创建新的一个axios，后面要再创建新的axios可以再实例化另一个对象
const service = axios.create({
    // baseURL:'/api',  // 配置基础路径
    // 配置基础路径  （本地连接）
    baseURL: process.env.VUE_APP_BASE_API,
    // 设置超时时间，服务器接收我发送的请求超过这个时间提示超时
    // timeout: 500 * 10,
    timeout: 3000 * 10,
    // 请求头类型
    headers: {
        // json字符串
        'Content-Type': 'application/json; charset=utf-8',
    }
})

let isShowLoginOut = true

// 请求拦截器
// 请求拦截器主要处理 token的统一注入问题
service.interceptors.request.use(config => {
    // 设置请求头
    if (config.url !== 'login' && store.state.common.token) {
        let token = store.state.common.token
        // 网络请求携带的请求头
        config.headers['Authorization'] = token
        config.headers['token'] = token
        // console.log('config.headers',config.headers)
    }
    // else {
    //     // 后端接口做了统一的，需要传一个死的token
    //     let token ='eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1c2VycyIsImlhdCI6MTY4NTk1NzE1NSwiZXhwIjoxNjg2MDAwMzU1LCJtb2JpbGUiOiIxODM4MTg1NzExMiIsInVzZXJuYW1lIjoiYWRtaW4ifQ.jKb6HoXIW8U0qcsL3lTLAPTCxDP154HBgOZ3v07ynDc'
    //     config.headers['Authorization'] = token
    //     config.headers['token'] = token
    // }

    // 在最后必须 return config
    return config
}, error => {
    Message.error('error',error.Message) // 提示错误信息
    return Promise.reject(error) // 通过Promise中静态对象reject返回执行错误，让当前的执行链跳出成功，直接进入catch
})

// 响应拦截器（俩个参数，一个执行成功的函数，一个执行失败的函数）
// 响应拦截器主要处理 返回的数据异常 和数据结构问题
service.interceptors.response.use(
    response => {
        if(response.data.code == 200){
            return response.data
        }else if( response.config.headers.download){
            // 返回异常
            if(response.data.byteLength < 100){
                let enc = new TextDecoder("utf-8");
                let uint8_msg = new Uint8Array(response.data);
                let data = JSON.parse(enc.decode(uint8_msg))
                Message.error(data.message)
                return Promise.reject(data)
            }
            return response
        }else if(response.data.code == 300){
            // token 失效,重新登录
            MessageBox.confirm('登录信息已时效，请重新登录', '确定登出', {
                confirmButtonText: '重新登录',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                logins.logout();
                router.push("/login");
            })
        }else {
            if(response.data.message){
                Message.error(response.data.message)
            }
            return Promise.reject(response.data)
        }
    },
    // 执行失败，统一异常错误处理
    error => {
        console.log('error',error)
        if (error && error.response) {
            switch (error.response.status) {
                case 400:
                    Message.error('请求出错')
                    break
                case 401:
                    Message.warning({
                        message: '授权失败，请重新登录'
                    })
                    setTimeout(async () => {
                        await logins.logout();
                        router.push("/login");
                    }, 1200)
                    break;
                case 300:
                    MessageBox.confirm('登录信息已时效，请重新登录', '确定登出', {
                        confirmButtonText: '重新登录',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        logins.logout();
                        router.push("/login");
                    })
                    break;
                case 403:
                    Message.error('拒绝访问')
                    break
                case 404:
                    Message.error('请求错误，未找到该资源')
                    break
                case 405:
                    Message.error('请求方法未允许')
                    break
                case 408:
                    Message.error('请求超时')
                    break
                case 500:
                    if(!isShowLoginOut){
                        return Promise.reject(error.message)
                    }
                    if(error.response.data.code == 300){
                        isShowLoginOut = false
                        // token 失效,重新登录
                        MessageBox.confirm('登录信息已时效，请重新登录', '确定登出', {
                            confirmButtonText: '重新登录',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            setTimeout(()=>{
                                isShowLoginOut = true
                            }, 5000)
                            logins.logout();
                            router.push("/login");
                        }).catch(err =>{
                            isShowLoginOut = true
                        })
                    }else {
                        Message.error('服务器异常，请稍后再试哦~')
                    }
                    break
                case 501:
                    Message.error('网络未实现')
                    break
                case 502:
                    Message.error('网络错误')
                    break
                case 503:
                    Message.error('服务不可用')
                    break
                case 504:
                    Message.error('网络超时')
                    break
                case 505:
                    Message.error('http版本不支持该请求')
                    break
                default:
                    Message.error(`连接错误：${error.response.status}`)
            }
        } else if (
            error.code === 'ECONNABORTED' ||
            error.message === 'Network Error' ||
            error.message.includes('timeout') ||
            !window.navigator.onLine
        ) {
            // 处理超时和断网
            Message.error('网络已断开，请查看你的网络连接~ 或刷新网页')
        } else {
            // 进行其它异常处理
            error.message = error.Message
        }
        // 向控制台返回错误信息
        return Promise.reject(error.message)
    })

// 导出/暴露该service，供外部可调用，相当于是个准许证
export default service